interface EnqueuedScript {
  id: string
  src: string
  handle: string
  extraData: string
}

export default function (input: EnqueuedScript[]) {
  const filteredData = input?.filter(item => item.handle.includes('userfeedback'))

  if (filteredData) {
    const headInnerData = filteredData.map(item => ({ innerHTML: item.extraData }))
    const headScriptsData = filteredData.map(item => item.src)

    useHead({
      script: [
        ...headInnerData,
      ],
      link: [{
        rel: 'stylesheet',
        href: 'https://bitcoin.pl/wp-content/plugins/userfeedback-premium/assets/vue/css/frontend.css',
      }],
    })

    for (const script of headScriptsData) {
      useScript({
        src: script,
        async: true,
        crossorigin: false,
      })
    }
  }
}
